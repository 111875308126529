import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import TimelineEvent from './TimelineEvent';

const Timeline = ({ events }) => {
    return (
        <div className='timeline-container'>
            <VerticalTimeline animate={false} lineColor={'#4b4b4b'} layout={'1-column-left'}>
                {events.map((event, index) => (
                    <TimelineEvent key={index} event={event} />
                ))}
            </VerticalTimeline>
        </div>
    );
};

export default Timeline;

