import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Workspace from './components/Workspace';
import LazyStats from './components/LazyStats';
import Pattern from './components/Pattern';
import './styles/App.css';
import MinimalistDarkScrollingLanding from './components/LandingPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MinimalistDarkScrollingLanding />} />
                <Route path="/login" element={<Login />} />
                <Route path="/yourspace" element={<Workspace />} />
                <Route path="/stats" element={<LazyStats />} />
                <Route path="/pattern" element={<Pattern />} />
            </Routes>
        </Router>
    );
};

export default App;