import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faCoffee, faTimes, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import 'react-vertical-timeline-component/style.min.css';

const colors = {
    start_work: '#317842',
    end_work: '#a52c38',
    start_break: '#f4a147',
    end_break: '#dc882e',
    add_description: '#6c757d',
};

const icons = {
    start_work: faPlay,
    end_work: faStop,
    start_break: faCoffee,
    end_break: faTimes,
    add_description: faStickyNote,
};

const typeName = {
    start_work: 'You have started Working',
    end_work: 'You have stopped Working',
    start_break: 'You went for a Break',
    end_break: 'You came Back From a Break',
    add_description: 'Description',
}

const TimelineEvent = ({ event }) => {
    const { type, description, timestamp } = event;
    const date = new Date(timestamp).toLocaleString();

    return (
        <VerticalTimelineElement
            contentStyle={{ background: colors[type], color: '#fff' }}
            date={date}
            contentArrowStyle={{ borderRight: '7px solid  black' }}
            icon={<FontAwesomeIcon icon={icons[type]} size='sm' />}
            iconStyle={{ background: colors[type], color: '#fff' }}
        >
            <h3>{typeName[type]}</h3>
            {description && <p>{description}</p>}
        </VerticalTimelineElement>
    );
};

export default TimelineEvent;
