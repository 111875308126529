import axios from "../api/axiosConfig";
import { setCookie, getCookie, removeCookie } from "../components/CookieManager";
// const log = (msg) => {
//     console.log(msg);
// }

export const registerUser = async (username, pattern) => {
    try {
        const response = await axios.post('/user.php', { username, pattern });
        const msg = response.data.message;

        if (msg === "user_exists") {
            return 'exists';
        } else if (msg === "user_added") {
            // localStorage.setItem('username', username);
            setCookie('username', username, 1);
            return 'added';
        } else {
            return 'error';
        }
    } catch (error) {
        console.log('Error registering user:', error);
        return 'error';
    }
};


export const loginUser = async (username) => {
    try {
        const response = await axios.get('/user.php', { params: { username } });
        if (response.data.message === "found") {
            // setCookie('username', username, 1); // must be set after the user enters the correct pattern
            return { status: true };
        } else {
            return { status: false, pattern: [] };
        }
    } catch (error) {
        console.error('Error logging in:', error);
        return { status: false, pattern: [] };
    }
};

export const CheckUser = async (pattern, username) => {
    try {
        const response = await axios.get('/user.php', { params: { checkPin: pattern, user: username } });
        if (response.data.message === "correct") {
            return { status: true };
        } else {
            return { status: false };
        }
    } catch (error) {
        console.error('Error logging in:', error);
        return { status: false };
    }
}

export const getUser = () => {
    const user = getCookie('username');
    if (user === '' || user === null || user === undefined) {
        return null;
    } else {
        return user;
    }
}

export const logOut = () => {
    removeCookie('username');
    return true;
}