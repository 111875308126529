import React, { useState } from 'react';
import PatternLock from 'react-pattern-lock/lib/components/PatternLock';
import 'bootstrap/dist/css/bootstrap.min.css';

const Pattern = () => {
    const [path, setPath] = useState([]);
    const [size] = useState(3);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const onChange = newPath => {
        setPath([...newPath]);
    };

    const onFinish = () => {
        if (path.join('-') === '0-1-2-3') {
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false)
                setPath([])
            }, 2000);
        } else {
            setError(true)
            setTimeout(() => {
                setError(false)
                setPath([])
            }, 2000);
        }
    };

    return (
        <div className='bg-dark mx-auto'>
            <h1>Pattern Lock Demo</h1>
            <div className="center" style={{ height: '300px', width: '300px' }}>
                <PatternLock
                    size={size}
                    onChange={onChange}
                    path={path}
                    onFinish={onFinish}
                    success={success}
                    error={error}
                    connectorThickness={5}
                />
            </div>
            <div className="output">Current Pattern: {path.join("-")}</div>
        </div>
    );
};

export default Pattern;
