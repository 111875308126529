import { lazy, Suspense } from "react";
import { Loading } from "react-vant";

const Statistics = lazy(() => import('./StatisticsPage'))

const LazyStats = () => {
    return (
        <>
            <Suspense fallback={<div className='loading'><Loading type='spinner' size={50} color='#fff' className='mx-auto' /></div>}>
                <Statistics />
            </Suspense>
        </>
    )
}
export default LazyStats;