import React, { useState } from 'react';
import PatternLock from 'react-pattern-lock/lib/components/PatternLock';
import { useNavigate } from 'react-router-dom';
import { loginUser, registerUser, CheckUser } from '../utils/auth';
import { Toast, Dialog } from 'react-vant';
import { ImCross } from "react-icons/im";
import { setCookie } from './CookieManager';

const BackgroundGraphic = () => (
    <svg className="-z-10 absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#2A3B4C" strokeWidth="1" />
            </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
    </svg>
);

const Login = () => {
    const [username, setUsername] = useState('');
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    // const [UserPattern, setUserPattern] = useState([]);

    // pattern related
    const [security, setSecurity] = useState(false);
    const size = 3;
    const [path, setPath] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [TheAlert, setTheAlert] = useState(false);
    const [TheErrorMsg, setTheErrorMsg] = useState(null);
    const [AlertClass, setAlertClass] = useState('')

    // register
    const [security2, setSecurity2] = useState(false);
    const [path2, setPath2] = useState([]);
    const [error2, setError2] = useState(false);
    const [success2, setSuccess2] = useState(false);
    const [TheAlert2, setTheAlert2] = useState(false);
    const [TheErrorMsg2, setTheErrorMsg2] = useState(null);
    const [AlertClass2, setAlertClass2] = useState('');


    const onChange = newPath => {
        setPath([...newPath]);
    };

    const onChange2 = newPath => {
        setPath2([...newPath]);
    };

    const onFinish = async () => {
        const pattern = await CheckUser(path.join('-'), username);
        if (pattern.status === true) {
            setCookie('username', username, 30);
            setSuccess(true);
            setTheAlert(false);
            setTimeout(() => {
                Toast.success('Login Success');
                navigate('/yourspace');
            }, 500);
        } else {
            setError(true)
            setTheErrorMsg('Wrong Pattern')
            setTheAlert(true)
            setAlertClass('strong-tilt-move-shake');
            setTimeout(() => {
                setError(false)
                setPath([])
                setAlertClass('');
            }, 500);
        }
    };


    const onFinish2 = () => {
        if (path2.length < 4) {
            setAlertClass2('strong-tilt-move-shake')
            setTheAlert2(true)
            setTheErrorMsg2('Need a Minimum of 4 Connected Dots')
            setError2(true);
            setTimeout(() => {
                setPath2([]);
                setAlertClass2('');
                setError2(false);
            }, 300)
        } else {
            setSuccess2(true);
            setError2(false);
            setTheAlert2(false);
            setAlertClass2('');
        }
    };

    const handleRegister = async () => {
        const result = await registerUser(username, path2.join('-'));
        // console.log(result)
        if (result === 'added') {
            navigate('/yourspace');
            Toast.success("success");
            // console.log('User registered successfully');

        } else if (result === 'exists') {
            Toast.fail('Username already exists');
            // console.log('Username already exists');

        } else {
            Toast.fail('Error While Registering');
            // console.log('Error registering user');
        }
    };

    const checkLogin = async () => {
        if (username === null || username === undefined || username === '' || username === ' ') {
            return Toast.fail({ message: "Please Enter a Username to continue", duration: 2000, className: 'text-break', position: 'bottom' });
        }
        const found = await loginUser(username);
        if (found.status) {
            // setUserPattern(found.pattern);
            setSecurity(true);
        } else {
            Dialog.show({
                visible,
                theme: 'round',
                title: '⚠️ User Not Found ⚠️',
                message: 'Do You want to Register as New User ?',
                className: 'text-dark',
                confirmButtonText: 'Confirm',
                closeOnClickOverlay: true,
                onConfirm: () => { setSecurity2(true) },
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                onCancel: () => { setVisible(false) }
            });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            checkLogin();
        }
    };

    // useEffect(() => {
    //     console.log('new user set again ',username);
    //     setUsername(getUser());
    // }, []);

    return (
        <>
            <Dialog
                visible={security}
                className='bg-transparent'
                showConfirmButton={false}
            >
                <div className={`text-center ${AlertClass}`}>
                    {TheAlert && <><div className={`alert alert-danger py-1 mx-3 text-center`}>{TheErrorMsg}</div></>}
                    <PatternLock
                        size={size}
                        onChange={onChange}
                        path={path}
                        onFinish={onFinish}
                        allowJumping={true}
                        success={success}
                        error={error}
                        connectorThickness={5}
                    />
                    <button className="btn btn-outline-light text-center rounded-5" onClick={() => setSecurity(false)}><ImCross /></button>
                </div>
            </Dialog>
            <Dialog
                visible={security2}
                className='bg-transparent'
                showConfirmButton={false}
            >
                <div className={`text-center ${AlertClass2}`}>
                    <div className='alert'>Set a Pattern for Your Account</div>
                    {TheAlert2 && <><div className={`alert alert-danger py-1 mx-3 text-center`}>{TheErrorMsg2}</div></>}
                    <PatternLock
                        size={size}
                        onChange={onChange2}
                        path={path2}
                        allowJumping={true}
                        error={error2}
                        success={success2}
                        onFinish={onFinish2}
                        connectorThickness={5}
                    />
                    <div className='col-12 mb-3'>
                        <button className='btn btn-warning mx-3 w-25' onClick={() => { setPath2([]); setSuccess2(false) }}>Reset</button>
                        <button className='btn btn-light w-25' onClick={() => handleRegister()}>Confirm</button>
                    </div>
                    <button className="btn btn-outline-light text-center rounded-5" onClick={() => setSecurity2(false)}><ImCross /></button>
                </div>
            </Dialog>
            <div className="login-container">
                <BackgroundGraphic />
                {/* <h2>Login</h2> */}
                <input
                    type="any"
                    placeholder="Enter Username"
                    value={username}
                    className='backdrop-blur-xxs'
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    onClick={checkLogin}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-lg text-lg transition duration-300"
                >
                    Login
                </button>
                {/* <button onClick={checkLogin}>Login</button> */}
                <div className='text-center text-secondary version'>
                    <p>Version {process.env.REACT_APP_VERSION}</p>
                </div>
            </div>
        </>
    );
};

export default Login;
